body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "roboto", "sans-serif";
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cursor {
  cursor: pointer;
}
