.container {
  display: flex;
  flex-direction: row;
}

.products-container {
  flex: 3; /* Takes 2/3 of the available space */
}

.cart-container {
  flex: 1; /* Takes 1/3 of the available space */
}
